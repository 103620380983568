import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './CustomAssignment.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/Assignments.jpg'

export default class CustomAssignments extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6 order-md-2">
          <h3 className="show-on-mobile">Custom Assignments</h3>
          <StaticImage
            className="image-border"
            src={image}
            alt="Custom Assignments"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="col-md-6 order-md-1">
          <h3 className="hide-on-mobile">Custom Assignments</h3>
          <br />
          <h4>
            Instructors can select scenarios, tailor AI avatar behaviors, and personalize AI-generated feedback through
            Ovation’s web portal.
            <br /> <br />
            Students can load their customized assignments into the software and begin practicing with just a few
            clicks. Detailed reports are automatically generated after each submission and provided to instructors for
            review.
          </h4>
        </div>
      </div>
    )
  }
}
