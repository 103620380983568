import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './PersonalizedOnboarding.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/PersonalizedOnboarding.jpg'

export default class PersonalizedOnboarding extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6 order-md-2">
          <h3 className="show-on-mobile">Personalized Onboarding</h3>
          <StaticImage className="img" src={image} alt="Personalized Onboarding" imgStyle={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-6 order-md-1">
          <h3 className="hide-on-mobile">Personalized Onboarding</h3>
          <br />
          <h4>
            Ovation staff will help set up your subscription and provide training, consisting of an in-app multiplayer
            session with up to 3 people from your school.
            <br /> <br />
            In addition, you‘ll have our{' '}
            <a target="_blank" href="https://docs.ovationvr.com/ovation/instructions/organization-information-packet">
              Organization Information Packet
            </a>{' '}
            containing step-by-step instructions for a successful deployment.{' '}
          </h4>
        </div>
      </div>
    )
  }
}
