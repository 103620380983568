import React from 'react'

import FlexibleConcurrentLicensing from '../FlexibleConcurrentLicensing/FlexibleConcurrentLicensing'
import DeviceSupport from '../DeviceSupport/DeviceSupport'
import PersonalizedOnboarding from '../PersonalizedOnboarding/PersonalizedOnboarding'

import './KeyFeatures.scss'
import DetailedReports from '../DetailedReports/DetailedReports'
import CustomAssignments from '../CustomAssignments/CustomAssignments'

export default class KeyFeatures extends React.Component {
  render() {
    return (
      <div className="key-features">
        <div className="wrapper">
          <h2 style={{ textAlign: 'center' }}>Key Features of Ovation‘s Organization Subscription</h2>
          <FlexibleConcurrentLicensing />
          <CustomAssignments />
          <DetailedReports
            openLightboxWithImageIndex={this.props.openLightboxWithImageIndex}
            carouselImages={this.props.reportsCarouselImages}
          />
          <PersonalizedOnboarding />
          <DeviceSupport />
        </div>
      </div>
    )
  }
}
