import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Managexr_Logo from '../../../static/managexr-logo.svg'
import ArborXR_Logo_Dark from '../../../static/ArborXR-Logo-Dark.svg'
import Meta_Logo from '../../../static/Meta.svg'

import './DeviceSupport.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/Headsets.jpg'

export default class DeviceSupport extends React.Component {
  render() {
    return (
      <div className="row device-support">
        <div className="col-md-6 order-md-1">
          <h3 className="show-on-mobile">Mobile Device Manager (MDM) Support</h3>
          <StaticImage className="img" src={image} alt="Personalized Onboarding" imgStyle={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-6 order-md-2">
          <h3 className="hide-on-mobile">Mobile Device Manager (MDM) Support</h3>
          <div className="mobile-center">
            <a target="_blank" href="https://www.managexr.com">
              <Managexr_Logo className="svg-device-support mobile-phone-center" />
            </a>
            <a target="_blank" href="https://arborxr.com">
              <ArborXR_Logo_Dark className="svg-device-support mobile-phone-center" />
            </a>
            <a target="_blank" href="https://forwork.meta.com/meta-for-education">
              <Meta_Logo className="svg-device-support mobile-phone-center" />
            </a>
          </div>

          <br />
          <h4>
            Schools with a fleet of VR headsets often rely on third-party mobile device managers to keep track of their
            headsets and control what software can be accessed by students.
            <br /> <br />
            Ovation supports{' '}
            <a target="_blank" href="https://www.managexr.com">
              ManageXR
            </a>
            {', '}
            <a target="_blank" href="https://arborxr.com">
              ArborXR
            </a>
            {', and '}
            <a target="_blank" href="https://forwork.meta.com/meta-for-education">
              Meta for Education/Business
            </a>
            . If you use a different MDM and need support, please contact us.
          </h4>
        </div>
      </div>
    )
  }
}
